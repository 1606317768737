import React from "react"

// Styles
import Layout from "./home/components/layout"
import GlobalStyles from "./home/components/global"

// Sections
import SectionOne from "./home/components/sectionOne"
import SectionTwo from "./home/components/sectionTwo"

// SEO
import SEO from "../components/seo"

// Smooth color transitions
import { AnimatedBg, Transition } from "scroll-background"

const Home = () => (
  <Layout>
    <SEO title="Home" />
    <AnimatedBg>
      <SectionOne />
      <Transition height="100px" from="#121212" to="#FFFFFF" />
      <SectionTwo />
    </AnimatedBg>

    <GlobalStyles />
  </Layout>
)

export default Home
