import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"

import { useSpring, animated, interpolate } from "react-spring"

const Footer = () => {
  return (
    <FooterDiv>
      <Menu>
        <ListItem>
          <StyledLink to="/">Kontakt</StyledLink>
        </ListItem>
        <ListItem>
          <StyledLink to="/invite-guide">Blog</StyledLink>
        </ListItem>
        <ListItem>
          <StyledLink to="/">Legal</StyledLink>
        </ListItem>
        <ListItem>
          <StyledLink to="/">Historie</StyledLink>
        </ListItem>
      </Menu>
      <CopyrightContainer>
        <Copyright>© 2020</Copyright>
      </CopyrightContainer>
    </FooterDiv>
  )
}

export default Footer

const FooterDiv = styled.div`
  margin: 0;
  padding: 0 10%;
  height: 120px;
  width: 100vw;

  background-color: #fdde68;

  display: flex;
  align-items: center;
  justify-content: space-between;
`

const Menu = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
  list-style-type: none;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
`

const ListItem = styled(animated.li)`
  margin: 0;
  padding: 0 5%;
  font-weight: bolder;
  color: #333;
  font-size: 1.5rem;
  text-align: center;
  transition-duration: 2s ease;

  :hover {
    transform: scale(1.1);
  }
`

const StyledLink = styled(props => <Link {...props} />)`
  margin: 0;
  padding: 0;
  font-weight: bolder;
  color: #333;
  font-size: 1.5rem;
  text-align: center;
`

const CopyrightContainer = styled.div`
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
`
const Copyright = styled.p`
  margin: 0;
  padding: 0;
  font-weight: bolder;
  color: #333;
  font-size: 1.5rem;
  text-align: center;
`
