import React from "react"
import styled from "styled-components"

import Footer from "./footer"

export default function Layout({ children }) {
  return (
    <LayoutDiv>
      {children}
      <Footer />
    </LayoutDiv>
  )
}

const LayoutDiv = styled.div`
  width: 100%;
  margin: 0 auto;
`
